<template>
    <div class="agentManage">


        <div class="searchForm_box">
            <el-form :inline="true" size="small" :model="searchForm" class="demo-form-inline">
                <el-form-item label="app版本名稱">
                    <el-input v-model="searchForm.version_name" placeholder="請輸入app版本名稱"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList">搜索</el-button>
                    <el-button type="success" @click="add">添加</el-button>
                </el-form-item>
            </el-form>
        </div>


        <el-table :data="list" border class="table" v-loading="tableLoading">
            <el-table-column width="100" label="ID" prop="app_id"></el-table-column>
            <el-input-number label="app版本號" prop="version_code"></el-input-number>
            <el-table-column label="app版本名稱" prop="version_name"></el-table-column>
            <el-table-column label="app更新內容" prop="version_content"></el-table-column>
            <el-table-column label="app下載鏈接" prop="url"></el-table-column>
            <el-table-column label="更新時間" prop="update_time"></el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="230">
                <template slot-scope="scope">
                    <el-button size="mini" icon="el-icon-edit" type="text" @click="edit(scope.row)">修改</el-button>
                    <el-button ize="mini" type="text" icon="el-icon-delete" @click="del(scope.row)">刪除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分頁 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>


        <!-- 編輯 -->
        <el-dialog :title="editTitle + '菜單'" :visible.sync="visible" width="600px" >
            <el-form ref="editForm" :model="editForm" :rules="editFormRules" label-width="130px" size="small">
                <el-form-item label="app版本號" prop="version_code">
                    <el-input v-model="editForm.version_code" placeholder="請輸入" />-->
                    <el-input-number
                            :controls="false"
                            v-model="editForm.version_code"
                            :min="0"
                            :max="1000"
                            :precision="0"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="app版本名稱" prop="version_name">
                    <el-input v-model="editForm.version_name" placeholder="請輸入" />
                </el-form-item>
                <el-form-item label="app更新內容" prop="version_content">
                    <el-input v-model="editForm.version_content" placeholder="請輸入" />
                </el-form-item>
                <el-form-item label="app下載鏈接" prop="url" :disabled="true">
                    <el-input v-model="editForm.url" placeholder="請選擇app安裝包" :disabled="true" />
                </el-form-item>
                <el-form-item label="app安裝包" prop="url">
                    <el-upload
                            class="upload-demo"
                            :before-remove="beforeRemove"
                            action=""
                            :on-change="uploadFile"
                            multiple
                            :limit="3"
                            :file-list="fileList">
                        <el-button size="small" type="primary">點擊上傳</el-button>
                        <div slot="tip" class="el-upload__tip">只能上傳jpg/png文件，且不超過500kb</div>
                    </el-upload>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" @click="submit">提交</el-button>
                    <el-button size="small" @click="reset">重置</el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                fileList:[],
                // 表格loading
                tableLoading: false,
                searchForm: {
                    version_name: "",
                },
                page: 1,
                pageSize: 50,
                total: 0,
                list: [],
                editForm: {
                    version_code: "",
                    version_name: "",
                    version_content: "",
                    url: "",
                },
                editTitle: '添加',
                visible: false,
                editFormRules: {
                    version_code: [
                        { required: true, type: 'number', message: "不能為空且為數字類型", trigger: "blur" },
                    ],
                    version_name: [
                        { required: true, message: "不能為空", trigger: "blur" },
                    ],
                    version_content: [
                        { required: true, message: "不能為空", trigger: "blur" },
                    ],
                },
            };
        },
        created() {
            this.getList();
        },
        mounted() {
        },
        methods: {
            beforeRemove(file, fileList) {
                return this.$confirm(`確定移除 ${ file.name }？`);
            },
            uploadFile(item){
                if (item.status !== 'ready') return;
                let formData = new FormData()   //將文件轉為FormData格式
                let file = item.raw
                formData.append('file', file)
                this.$http('/admin/app/upload', formData, 'post').then((res) => {
                    this.editForm.url = res.data.url
                })
            },
            getList() {
                let params = {
                    page: this.page,
                    pageSize: this.pageSize,
                    version_name: this.searchForm.version_name,
                }
                this.$http('/admin/app/list', params, 'post').then((res) => {
                    this.tableLoading = false
                    this.list = res.data.list;
                    this.total = res.data.count;
                })
            },
            // 編輯
            edit(row) {
                console.log(row);
                this.reset();
                this.visible = true;
                this.editForm = row;
                this.getList();
            },
            // 添加
            add() {
                this.reset();
                this.visible = true;
                this.getList();
            },
            // 刪除
            del(row) {
                this.$confirm(
                    "此操作將永久刪除數據, 是否繼續?","提示",{
                        confirmButtonText: "確定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        // const delLoad = this.$loading("正在刪除..");
                        this.$http('/admin/app/del',row,'post').then((res)=>{
                            console.log(res);
                            this.getList();
                            if (res.code == 200) {
                                this.$message.success(res.msg);
                                // window.location.reload();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    })
                    .catch(() => {});
            },
            //  重置
            reset() {
                this.editForm = {
                    version_code: "",
                    version_name: "",
                    version_content: "",
                    url: "",
                };
            },
            submit() {
                this.$refs["editForm"].validate((valid) => {
                    if (valid) {
                        this.$http('/admin/app/edit',this.editForm,'post').then((res)=>{
                            if(res.code == 200){
                                this.$message.success(res.msg);
                                this.visible = false;
                                this.getList();
                            }else{
                                this.$message.error(res.msg);
                            }

                        })

                    }
                });
            },
            menuReset() {
                this.editForm = {};
            },
            // 分頁
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/agentManage.scss";
</style>